export const BASE_API = "https://app.saturnoretail.com/api/v1/";//per chimate API https://app.bikinilovers.it/api/v1/website https://test.bikinilovers.it/api/v1/website
export const BASE_BACKEND_URL = "https://app.saturnoretail.com/"; //https://app.bikinilovers.it/ https://test.bikinilovers.it/
export const PATH_APP = "http://localhost:5173/"; //http://app.bikinilovers.it:8888/"; http://http://localhost:5173/ http://test.bikinilovers.it:8888/ https://www.bikinilovers.it/
export const PRODUCTS_NUM = 12;
export const PAYPAL_ACCOUNT = "";//buyers@bikinilovers.it //renzcap-facilitator@gmail.com
export const PAYPAL_URL = ""; //https://www.paypal.com/cgi-bin/webscr https://sandbox.paypal.com/cgi-bin/webscr
export const etoken = "e01e10b87834837fcb1fabaf7df755771b9f66bd";

export const cartIDkey = "cartIDFI";

export const STRIPE_PUBLIC_KEY = "pk_test_bPHjhQ5JphnZ7PpbSclajVxG00xBVLW5x4"; //"pk_test_bPHjhQ5JphnZ7PpbSclajVxG00xBVLW5x4"; i coloniali
export const IDSALESPOINT = "e6821585-d0d8-4f8e-9add-3abfc80487c2";